<template>
  <div class="review-photo-slider">
    <div class="slider-wrapper">
      <!--      <div class="item" v-for="(p,idx) in modalParams.photos" :key="idx" :style="{backgroundImage: `url('${ computedImageFileUrl(p)}')`}">-->
      <!--      </div>-->
      <template v-for="(p,idx) in modalParams.photos">
        <img v-if="idx === state.currentPosition" :key="idx" :src="computedImageFileUrl(p)">
      </template>
    </div>
    <template v-if="modalParams.photos.length > 1">
      <div class="prev slider-btn" @click="prev()" v-if="state.currentPosition !== 0">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="next slider-btn" @click="next()" v-if="state.currentPosition + 1 < modalParams.photos.length">
        <i class="fa fa-angle-right"></i>
      </div>
      <div class="current">
        <span>{{ `${state.currentPosition + 1} / ${modalParams.photos.length}` }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {computed, defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalReviewPhotoSlider";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md", noneShadow: true,});

      if (modalParams.currentNum) {
        state.currentPosition = modalParams?.currentNum || 0;
      }

      if (!modalParams.photos?.length) {
        return store.commit("closeModal", {
          name: component.name,
          onClose() {
            store.commit("setSwingMessage", "사진을 불러오지 못했습니다.");
          }
        });
      }
    });

    const state = reactive({
      currentPosition: 0,
    });

    const modalParams = store.getters.modalParams(component);

    const computedImageFileUrl = computed(() => {
      return (fileUrl) => {
        let file = "";
        file += fileUrl?.filePath ? fileUrl?.filePath : "";
        file += fileUrl?.fileSaveName ? fileUrl?.fileSaveName : "";

        return file;
      };
    });

    const next = () => {
      if (state.currentPosition >= modalParams.photos.length - 1) {
        return;
      } else {
        state.currentPosition += 1;
      }
    };

    const prev = () => {
      if (state.currentPosition <= 0) {
        return;
      } else {
        state.currentPosition -= 1;
      }

    };

    return {component, state, modalParams, computedImageFileUrl, next, prev};
  }
});
</script>

<style lang="scss" scoped>
.review-photo-slider {
  height: 100%;
  position: relative;

  .slider-wrapper {
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  .slider-btn {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    width: $px30;
    height: $px60;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    > i {
      font-size: $px26;
      position: absolute;
      top: 50%;
      transform: translate(-50%, calc(-50% - $px1));
    }

    &.prev {
      left: 0;

      > i {
        left: calc(50% - $px2);
      }
    }

    &.next {
      right: 0;

      > i {
        left: calc(50% + $px2);
      }
    }
  }

  .current {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $px4;
    color: #fff;
    font-size: $px12;
    padding: $px8 $px12;
    position: absolute;
    bottom: $px10;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>